import Home from './home'
import PageError from '@components/common/PageError'
import IProject from '@models/projects/interfaces/project.interface'
import useProjects from '@contexts/ProjectsContext/hooks/useProjects'
import client from 'contentful/client'
import { projectThumbnailFragment } from 'contentful/project'
import { gql } from '@apollo/client'
import { GetStaticProps } from 'next'
import { useEffect } from 'react'

interface IndexProps {
  projects: {
    ended: IProject[]
    upcoming: IProject[]
    featured: IProject
  }
  error: string
}

const Index: React.FC<IndexProps> = ({ projects, error }) => {
  const { setFetchedProjects } = useProjects()

  useEffect(() => {
    if (projects.ended.length) {
      setFetchedProjects(projects)
    }
  }, [])

  if (error) {
    return <PageError />
  }

  return <Home />
}

export const getStaticProps: GetStaticProps = async () => {
  const { data, error } = await client.query({
    query: gql`
      query ProjectIndexQuery($preview: Boolean!) {
        ended: projectCollection(where: { ended: true }, preview: $preview) {
          items {
            ...projectThumbnailFields
          }
        }
        upcoming: projectCollection(
          where: { ended: false }
          preview: $preview
        ) {
          items {
            ...projectThumbnailFields
          }
        }
        featured: featuredProject(
          id: "1ZQJKDNVhgq64iyvtB2FEU"
          preview: $preview
        ) {
          featuredProject {
            ...projectThumbnailFields
          }
        }
      }
      ${projectThumbnailFragment}
    `,
    variables: { preview: process.env.CONTENTFUL_PREVIEW_MODE },
    fetchPolicy: 'network-only'
  })

  return {
    props: {
      projects: {
        ended: data.ended.items || [],
        upcoming: data.upcoming.items || [],
        featured: data.featured.featuredProject || null
      },
      error: error?.message || ''
    },
    revalidate: 60
  }
}

export default Index
