import tw, { css } from 'twin.macro'

const styles = ({ fullHeight }) => css`
  ${fullHeight ? tw`min-h-screen` : tw`min-h-full`}

  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${tw`px-10`}
  .title {
    ${tw`mb-5`}
  }
`

export default styles
