import { Offline, Online } from 'react-detect-offline'
import styles from './styles'
import { Typography, Image, Title } from '@jeromevvb/equinox-lib'
import NoxImg from '@public/nox.png'

export interface PageErrorProps {
  maintenance?: boolean
  fullHeight?: boolean
}

const PageError: React.FC<PageErrorProps> = (props) => {
  const { maintenance, fullHeight = true } = props

  if (maintenance) {
    return (
      <div css={styles({ fullHeight })} data-testid="maintenance">
        <Image
          alt="Nox"
          src={NoxImg.src}
          className="w-64 mb-6"
          showPreloader={false}
        />
        <Title size="large" className="title">
          We are improving your experience
        </Title>

        <Typography>We'll back shortly</Typography>
      </div>
    )
  }

  return (
    <div css={styles({ fullHeight })} data-testid="error">
      <Title size="large" className="title">
        Uh Oh! We are having an issue.
      </Title>
      <Online>
        <Typography>Please try again in few minutes.</Typography>
      </Online>
      <Offline>
        <Typography>
          It looks you are offline. Please check your connection.
        </Typography>
      </Offline>
    </div>
  )
}

export default PageError
